import React from 'react'
import { Header, Segment, Icon } from 'semantic-ui-react'

import Layout from '../components/layout'
import { LayoutSection } from '../components/layout-components'

const NotFoundPage = () => (
  <Layout text>
    <LayoutSection>
      <Header size="huge" textAlign="center">
        Page not found.
      </Header>
      <Segment basic padded textAlign="center">
        <Icon name="dont" size="massive" />
      </Segment>
    </LayoutSection>
  </Layout>
)

export default NotFoundPage
